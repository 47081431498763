









































































import { Component, Vue } from 'vue-property-decorator';
import BaseDataModule from '@/store/modules/base-data';
import MeetingModule from '@/store/modules/meeting';
import MeetingListQuery from '@/store/entities/meeting-list-query';
import { MeetingModeEnum, StateEnum } from '@/store/entities/state-enum';
import MeetingInfo from '@/store/entities/meeting-info';
import SubMeetingView from '@/views/components/SubMeetingView.vue';

@Component({ components: { SubMeetingView } })
export default class CycleMeetingList extends Vue {
  query: MeetingListQuery = new MeetingListQuery();
  currentPage: number = MeetingModule.currentPage;
  pageSize: number = MeetingModule.pageSize;
  meetingId: number = 0;

  dataLoading: boolean = true;
  isShowDetail: boolean = false;

  get meetingTypeList() {
    return BaseDataModule.meetingTypeList;
  }

  get meetingStatusList() {
    return BaseDataModule.meetingStatusList;
  }

  get list() {
    return MeetingModule.list;
  }

  get totalCount() {
    return MeetingModule.totalCount;
  }

  seeView(id: number) {
    this.isShowDetail = true;
    this.meetingId = id;
  }

  resetSearch() {
    (this.$refs.searchMeeting as any).resetFields();
    this.query.startDate = '';
    this.query.endDate = '';
    this.searchData();
  }

  /*取消会议按钮显示*/
  showCancelAll(row: MeetingInfo) {
    /*先决条件 草稿、待审批、审批通过*/
    let isMatchState = row.status == StateEnum.Draft || row.status == StateEnum.Pending || row.status == StateEnum.Adopt;
    if (!isMatchState) return false;

    let _currentUser = BaseDataModule.currentUser;
    /* 是否 管理员 */
    if (_currentUser.isAdmin) return true;
    /* 是否 本人 */
    if (row.subscriberId == Number(_currentUser.employeeID)) return true;

    return false;
  }

  async cancelAll(id: number) {
    this.$confirm('确认取消该会议的所有子会议？', '温馨提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(async () => {
        await MeetingModule.cancelAllSubMeeting({ meetingId: id }).then((res) => {
          this.$message.success('操作成功');
          this.getData();
        });
      })
      .catch(() => {
        // this.isCanceling = false;
      });
  }

  async searchData() {
    this.currentPage = 1;
    MeetingModule.setPage(1);
    await this.getData();
  }

  async sortChange(sort: any) {
    if (!sort.order) {
      this.query.sorting = '';
    } else if (!sort.column.sortBy) {
      this.query.sorting = `${sort.prop} ${sort.order}`.replace('ending', '');
    } else {
      this.query.sorting = `${sort.column.sortBy} ${sort.order}`.replace('ending', '');
    }
    await this.getData();
  }

  async pageChange(page: number) {
    MeetingModule.setPage(page);
    await this.getData();
  }

  async sizeChange(count: number) {
    MeetingModule.setPageSize(count);
    await this.getData();
  }

  async getData() {
    this.dataLoading = true;

    this.query.meetingMode = MeetingModeEnum.Cycle;
    await MeetingModule.getAll(this.query).finally(() => {
      setTimeout(() => {
        this.dataLoading = false;
      }, 300);
    });
  }

  async mounted() {
    let userId = this.$route.query.id;
    if (userId) {
      await BaseDataModule.getEmployeeById({ employeeId: userId });
    }

    this.getData();
    await BaseDataModule.getMeetingType();
    await BaseDataModule.getMeetingApprovalState();
    // await BaseDataModule.getOrganizationList();
  }
}
