





















































import { Component, Vue, Prop } from "vue-property-decorator";
import { StateEnum } from '@/store/entities/state-enum'
import BaseDataModule from "@/store/modules/base-data";
import MeetingModule from "@/store/modules/meeting";
import EventView from "./EventView.vue"

@Component({ components: { EventView } })
export default class SubMeetingView extends Vue {
  @Prop({ type: Number }) id!: number;
  @Prop({ type: Boolean }) isShow!: boolean;

  dataLoading: boolean = true;
  isShowView: boolean = false;

  meetingId: number = 0;

  get meetingModeList() {
    return BaseDataModule.meetingModeList;
  }

  get meetingTypeList() {
    return BaseDataModule.meetingTypeList;
  }

  get meetingStatusList() {
    return BaseDataModule.meetingStatusList;
  }

  get list() {
    return MeetingModule.subMeetingList;
  }

  get currentInfo() {
    return MeetingModule.currentInfo;
  }

  /*取消会议按钮显示*/
  get isShowCancelAll() {
    /*先决条件 草稿、待审批、审批通过*/
    let isMatchState = (this.currentInfo.status == StateEnum.Draft ||
      this.currentInfo.status == StateEnum.Pending ||
      this.currentInfo.status == StateEnum.Adopt);

    if (!isMatchState) return false;
    let _currentUser = BaseDataModule.currentUser;
    /* 是否 管理员 */
    if (_currentUser.isAdmin) return true;
    /* 是否 本人 */
    if (this.currentInfo.subscriberId == Number(_currentUser.employeeID)) return true;

    return false;
  }

  seeView(id: number) {
    this.meetingId = id;
    this.isShowView = true;
  }

  close() {
    this.$emit("update:isShow", false);
  }

  async cancelMeeting(id: number) {
    this.$confirm("确认取消会议？", "提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      await MeetingModule.cancelMeeting({ meetingId: id }).then((res) => {
        this.$message.success("操作成功");
        this.getData();
      })
    }).catch(() => {
      //this.isCanceling = false;
    });
  }

  async cancelAll() {
    this.$confirm("确认取消该会议的所有子会议？", "温馨提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      await MeetingModule.cancelAllSubMeeting({ meetingId: this.id }).then((res) => {
        this.$message.success("操作成功");
        this.getData();
      })
    }).catch(() => {
      //this.isCanceling = false;
    });
  }

  async open() {
    this.getData();
    await MeetingModule.get({ id: this.id });
  }

  async getData() {
    this.dataLoading = true;
    await MeetingModule.getSubMeetingById({ meetingId: this.id }).finally(() => {
      setTimeout(() => {
        this.dataLoading = false;
      }, 300);
    });
  }
}
